import { useContext, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import {SideNav, Header, Preloader} from '../partials/layout.js';
import { PlantContext } from '../utils/constants.js';
const moment = require("moment");
const XLSX = require('xlsx');

// Monthly Inverter Report
function MonthlyReport(){
  const plant = useContext(PlantContext);
  const [inverters, setInverters] = useState([]);
  const [selectedInverter, setSelectedInverter]=useState("");
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const CURRENT_DATE = moment().format(moment.HTML5_FMT.DATE);
  const [fromDate, setFromDate] = useState(CURRENT_DATE);
  const [toDate, setToDate] = useState(CURRENT_DATE);
  const isLoggedIn = sessionStorage.getItem("userToken");

  useEffect(()=>{
    getInverters();    
  },[]);

  const getInverters = async()=> {
    try {
      const response = await fetch(`/api/inverters/${plant.id}`);
      const json = await response.json();
      setInverters(json);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const getReport = async()=> {
    setLoading(true);
    try {
      const response = await fetch(`/api/inverter-summary/${selectedInverter}?fromDate=${fromDate}&toDate=${toDate}`);
      const json = await response.json();
      setData(json.length?json:[]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (e)=> {
    var name = e.target.name;
    var value = moment(e.target.value).format("YYYY-MM-DD");
    if(name==="startDate"){
      setFromDate(value);
    }else{
      setToDate(value);
    }
  };

  const handleOnExport = (e) => {
    var elt = document.getElementById('dataTable');
    var wb = XLSX.utils.table_to_book(elt, {sheet:"GENERATION_REPORT"});
    var fileName = `GENERATION_REPORT_${fromDate}_${toDate}.xlsx`;    
    XLSX.writeFile(wb, fileName);
  };

  if(isLoggedIn){    
    return (
      <>
      {/*<!-- Header-->*/}
      <Header/>
      {/*<!-- Header End-->*/}
      {/*<!-- Main Container-->*/}
      <div className="container-fluid main">
        <div className="row">
          <SideNav/>
          <div className="main">
            <div className="container-fluid py-2">
            <h5 className="fw-semibold text-uppercase text-decoration-underline mb-3 text-center">Report / Monthly Inverter Data</h5>
              <div className="row">
                <div className="col-lg-8">
                  <div className="input-group mb-3 align-items-center">
                    <label className="me-2" htmlFor="startDate">From Date:</label>
                    <input type="date" className="form-control" name="startDate" value={fromDate} onChange={handleDateChange} />
                    <label className="ms-3 me-2" htmlFor="startDate">To Date:</label>
                    <input type="date" className="form-control" name="endDate" value={toDate} onChange={handleDateChange} />
                    <select className="form-select" name="inverter" value={selectedInverter} onChange={(e)=> setSelectedInverter(e.target.value)}>
                      <option value="">-- Select Inverter --</option>
                      {
                        inverters.map(inv=> 
                          <option key={"INV"+inv.id} value={inv.id}>{inv.device_id}</option>
                        )
                      }
                    </select>
                    <button type="button" className="btn btn-secondary" id="search" onClick={getReport} disabled={isLoading?true:false} >Search</button>
                    <button type="button" className="btn btn-success" onClick={handleOnExport} disabled={isLoading?true:false} >XLSX</button>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <table id="dataTable" className="table table-sm table-bordered report-table">
                  <thead className="table-dark">
                    <tr>
                      <th className="text-light text-end">Date</th>
                      <th className="text-light text-end">Daily Power Supplied (KWH)</th>
                      <th className="text-light text-end">Daily Energy Supplied (KVAH)</th>
                      <th className="text-light text-end">Total Power Supplied (KWH)</th>
                      <th className="text-light text-end">Total Energy Supplied (KVAH)</th>
                      <th className="text-light text-end">Peak Power Generated</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isLoading?(
                      <>
                        {
                          data.map((e)=>
                            <tr key={"MM"+e.id}>
                              <td>{moment(e.rdate).format("DD/MMM/YYYY")}</td>
                              <td className="text-end">{e.todaykwh}</td>
                              <td className="text-end">{e.todaykvah}</td>
                              <td className="text-end">{e.totalkwh}</td>
                              <td className="text-end">{e.totalkvah}</td>
                              <td className="text-end">{e.peakpower}</td>
                            </tr>
                          )     
                        }
                      </>
                    ):(
                      <Preloader />
                    )}                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<!-- Main Container End-->*/}
      </>
    )
  }else{
    return (
      <Preloader />
    );
  }
}

// Daily Inverter One Minute Data Report
function InverterDailyReport(){
  const plant = useContext(PlantContext);
  const [inverters, setInverters] = useState([]);
  const [selectedInverter, setSelectedInverter] = useState("");
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const CURRENT_DATE = moment().format(moment.HTML5_FMT.DATE);
  const [fromDate, setFromDate] = useState(CURRENT_DATE);
  const [toDate, setToDate] = useState(CURRENT_DATE);
  const isLoggedIn = sessionStorage.getItem("userToken");

  useEffect(()=>{
    getInverters();    
  },[]);

  const getInverters = async()=> {
    try {
      const response = await fetch(`/api/inverters/${plant.id}`);
      const json = await response.json();
      setInverters(json);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const getReport = async()=> {
    setLoading(true);
    try {
      const response = await fetch(`/api/inverter-omd-summary/${selectedInverter}?fromDate=${fromDate}&toDate=${toDate}`);
      const json = await response.json();
      setData(json.length?json:[]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (e)=> {
    var name = e.target.name;
    var value = moment(e.target.value).format("YYYY-MM-DD");
    if(name==="startDate"){
      setFromDate(value);
    }else{
      setToDate(value);
    }
  };

  const handleOnExport = (e) => {
    var elt = document.getElementById('dataTable');
    var wb = XLSX.utils.table_to_book(elt, {sheet:"INVERTER_ONE_MIN_DATA_REPORT"});
    var fileName = `INVERTER_ONE_MIN_DATA_REPORT_${fromDate}_${toDate}.xlsx`;
    XLSX.writeFile(wb, fileName);
  };

  if(isLoggedIn){        
    return (
      <>
      {/*<!-- Header-->*/}
      <Header/>
      {/*<!-- Header End-->*/}
      {/*<!-- Main Container-->*/}
      <div className="container-fluid main">
        <div className="row">
          <SideNav/>
          <div className="main">
            <div className="container-fluid py-2">
            <h5 className="fw-semibold text-uppercase text-decoration-underline mb-3 text-center">Report / Inverter One Minute Data</h5>
              <div className="row">
                <div className="col-lg-8">
                  <div className="input-group mb-3 align-items-center">
                    <label className="me-2" htmlFor="startDate">From Date:</label>
                    <input type="date" className="form-control" name="startDate" value={fromDate} onChange={handleDateChange} />
                    <label className="ms-3 me-2" htmlFor="startDate">To Date:</label>
                    <input type="date" className="form-control" name="endDate" value={toDate} onChange={handleDateChange} />
                    <select className="form-select" name="inverter" value={selectedInverter} onChange={(e)=> setSelectedInverter(e.target.value)}>
                      <option value="">-- Select Inverter --</option>
                      {
                        inverters.map(inv=> 
                          <option key={"INV"+inv.id} value={inv.id}>{inv.device_id}</option>
                        )
                      }
                    </select>
                    <button type="button" className="btn btn-secondary" id="search" onClick={getReport} disabled={isLoading?true:false} >Search</button>
                    <button type="button" className="btn btn-success" onClick={handleOnExport} disabled={isLoading?true:false} >XLSX</button>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <table id="dataTable" className="table table-sm table-bordered report-table">
                  <thead className="table-dark">
                    <tr>
                      <th className="text-light text-end">Date</th>
                      <th className="text-light text-end">Daily Power Supplied (KWH)</th>
                      <th className="text-light text-end">Daily Energy Supplied (KVAH)</th>
                      <th className="text-light text-end">Total Power Supplied (KWH)</th>
                      <th className="text-light text-end">Total Energy Supplied (KVAH)</th>
                      <th className="text-light text-end">Peak Power Generated</th>
                    </tr>
                  </thead>
                  <tbody>
                  {!isLoading?(
                      <>
                        {
                          data.map((e)=>
                            <tr key={"IOMD"+e.id}>
                              <td>{e.rdate}</td>
                              <td className="text-end">{e.todaykwh}</td>
                              <td className="text-end">{e.todaykvah}</td>
                              <td className="text-end">{e.totalkwh}</td>
                              <td className="text-end">{e.totalkvah}</td>
                              <td className="text-end">{e.peakpower}</td>
                            </tr>
                          )
                        }
                      </>
                    ):(
                      <Preloader />
                    )}      
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<!-- Main Container End-->*/}
      </>
    )
  }else{
    return (
      <Preloader />
    );
  }
}

// Daily SMB One Minute Data Report
function SmbDailyReport(){
  const plant = useContext(PlantContext);
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const CURRENT_DATE = moment().format(moment.HTML5_FMT.DATE);
  const [fromDate, setFromDate] = useState(CURRENT_DATE);
  const [toDate, setToDate] = useState(CURRENT_DATE);
  const isLoggedIn = sessionStorage.getItem("userToken");

  useEffect(()=>{
    getReport();
  },[]);

  const getReport = async()=> {
    setLoading(true);
    try {
      const response = await fetch(`/api/smb-omd-summary/${plant.id}?fromDate=${fromDate}&toDate=${toDate}`);
      const json = await response.json();
      setData(json.length?json:[]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (e)=> {
    var name = e.target.name;
    var value = moment(e.target.value).format("YYYY-MM-DD");
    if(name==="startDate"){
      setFromDate(value);
    }else{
      setToDate(value);
    }
  };

  const handleOnExport = (e) => {
    var elt = document.getElementById('dataTable');
    var wb = XLSX.utils.table_to_book(elt, {sheet:"SMB_ONE_MIN_DATA_REPORT"});
    var fileName = `SMB_ONE_MIN_DATA_REPORT_${fromDate}_${toDate}.xlsx`;
    XLSX.writeFile(wb, fileName);
  };

  if(isLoggedIn){     
    return (
      <>
      {/*<!-- Header-->*/}
      <Header/>
      {/*<!-- Header End-->*/}
      {/*<!-- Main Container-->*/}
      <div className="container-fluid main">
        <div className="row">
          <SideNav/>
          <div className="main">
            <div className="container-fluid py-2">
            <h5 className="fw-semibold text-uppercase text-decoration-underline mb-3 text-center">Report / SMB One Minute Data</h5>
              <div className="row">
                <div className="col-lg-8">
                  <div className="input-group mb-3 align-items-center">
                    <label className="me-2" htmlFor="startDate">From Date:</label>
                    <input type="date" className="form-control" name="startDate" value={fromDate} onChange={handleDateChange} />
                    <label className="ms-3 me-2" htmlFor="startDate">To Date:</label>
                    <input type="date" className="form-control" name="endDate" value={toDate} onChange={handleDateChange} />
                    <button type="button" className="btn btn-secondary" id="search" onClick={getReport} disabled={isLoading?true:false} >Search</button>
                    <button type="button" className="btn btn-success" onClick={handleOnExport} disabled={isLoading?true:false} >XLSX</button>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <table id="dataTable" className="table table-sm table-bordered report-table">
                  <thead className="table-dark">
                    <tr>
                      <th className="text-light text-end">Date & Time</th>
                      <th className="text-light text-end">Device ID</th>
                      {
                        Array(20).fill(0).map((e,i)=>
                          <th key={"smb"+i} className="text-light text-end">String{i+1}</th>      
                        )
                      }                      
                      <th className="text-light text-end">DC Voltage</th>
                    </tr>
                  </thead>
                  <tbody>
                  {!isLoading?(
                      <>
                        {
                          data.map((row)=>
                            <tr key={"SMBOMD"+row.id}>
                              <td>{row.rdate}</td>
                              <td>{row.device_id}</td>
                              {
                                Array(20).fill(0).map((e,i)=>
                                  <td key={"smbvalue"+i} className="text-end">{row["string"+(i+1)]}</td>
                                )
                              }        
                              <td className="text-end">{row.dcvoltage}</td>     
                            </tr>
                          )
                        }
                      </>
                    ):(
                      <Preloader />
                    )}   
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<!-- Main Container End-->*/}
      </>
    )
  }else{
    return (
      <Preloader />
    );
  }
}

// Daily WMS One Minute Data Report
function WmsDailyReport(){
  const plant = useContext(PlantContext);
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const CURRENT_DATE = moment().format(moment.HTML5_FMT.DATE);
  const [fromDate, setFromDate] = useState(CURRENT_DATE);
  const [toDate, setToDate] = useState(CURRENT_DATE);
  const isLoggedIn = sessionStorage.getItem("userToken");

  useEffect(()=>{
    getReport();
  },[]);

  const getReport = async()=> {
    setLoading(true);
    try {
      const response = await fetch(`/api/wms-omd-summary/${plant.id}?fromDate=${fromDate}&toDate=${toDate}`);
      const json = await response.json();
      setData(json.length?json:[]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (e)=> {
    var name = e.target.name;
    var value = moment(e.target.value).format("YYYY-MM-DD");
    if(name==="startDate"){
      setFromDate(value);
    }else{
      setToDate(value);
    }
  };

  const handleOnExport = (e) => {
    var elt = document.getElementById('dataTable');
    var wb = XLSX.utils.table_to_book(elt, {sheet:"WMS_ONE_MIN_DATA_REPORT"});
    var fileName = `WMS_ONE_MIN_DATA_REPORT_${fromDate}_${toDate}.xlsx`;
    XLSX.writeFile(wb, fileName);
  };

  if(isLoggedIn && !isLoading){    
    return (
      <>
      {/*<!-- Header-->*/}
      <Header/>
      {/*<!-- Header End-->*/}
      {/*<!-- Main Container-->*/}
      <div className="container-fluid main">
        <div className="row">
          <SideNav/>
          <div className="main">
            <div className="container-fluid py-2">
            <h5 className="fw-semibold text-uppercase text-decoration-underline mb-3 text-center">Report / WMS One Minute Data</h5>
              <div className="row">
                <div className="col-lg-8">
                  <div className="input-group mb-3 align-items-center">
                    <label className="me-2" htmlFor="startDate">From Date:</label>
                    <input type="date" className="form-control" name="startDate" value={fromDate} onChange={handleDateChange} />
                    <label className="ms-3 me-2" htmlFor="startDate">To Date:</label>
                    <input type="date" className="form-control" name="endDate" value={toDate} onChange={handleDateChange} />
                    <button type="button" className="btn btn-secondary" id="search" onClick={getReport} disabled={isLoading?true:false} >Search</button>
                    <button type="button" className="btn btn-success" onClick={handleOnExport} disabled={isLoading?true:false} >XLSX</button>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <table id="dataTable" className="table table-sm table-bordered report-table">
                  <thead className="table-dark">
                    <tr>
                      <th className="text-light text-end">Date & Time</th>                      
                      <th className="text-light text-end">Irradiation Power (KWH)</th>
                      <th className="text-light text-end">Today Irradiation Power (KWH)</th>
                      <th className="text-light text-end">Temperature (°C)</th>
                    </tr>
                  </thead>
                  <tbody>
                  {!isLoading?(
                      <>
                        {
                          data.map((e)=>
                            <tr key={"WMSOMD"+e.id}>
                              <td>{e.rdate}</td>
                              <td className="text-end">{e.irrpowerkwh}</td>
                              <td className="text-end">{e.todayirrpowerkwh}</td>
                              <td className="text-end">{e.temperature}</td>        
                            </tr>
                          )
                        }
                      </>
                    ):(
                      <Preloader />
                    )}  
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<!-- Main Container End-->*/}
      </>
    )

  }else{
    return (
      <Preloader />
    );
  }
}

function ReportMenu(){
  return (
    <>
      {/*<!-- Header-->*/}
      <Header/>
      {/*<!-- Header End-->*/}
      {/*<!-- Main Container-->*/}
      <div className="container-fluid main">
        <div className="row">
          <SideNav/>
          <div className="main">
            <div className="container-fluid pt-5 pb-2">
              <div className="row align-items-center justify-content-center py-1">
                <Link to="/monthly-report" className="card report-cards text-decoration-none text-uppercase rounded-0 shadow-sm mx-1">
                  <div className="card-body">
                    <h5 className="card-title mb-0 text-center">Monthly Report</h5>                  
                  </div>
                </Link>
              </div>
              <div className="row align-items-center justify-content-center py-1">
                <Link to="/inverter-daily-report" className="card report-cards text-decoration-none text-uppercase rounded-0 shadow-sm mx-1">
                  <div className="card-body">
                    <h5 className="card-title mb-0 text-center">Inverter One Minute Data Report</h5>                  
                  </div>               
                </Link>
              </div>
              <div className="row align-items-center justify-content-center py-1">
                <Link to="/smb-daily-report" className="card report-cards text-decoration-none text-uppercase rounded-0 shadow-sm mx-1">
                  <div className="card-body">
                    <h5 className="card-title mb-0 text-center">SMB One Minute Data Report</h5>                 
                  </div>               
                </Link>
              </div>
              <div className="row align-items-center justify-content-center py-1">
                <Link to="/wms-daily-report" className="card report-cards text-decoration-none text-uppercase rounded-0 shadow-sm mx-1">
                  <div className="card-body">
                    <h5 className="card-title mb-0 text-center">WMS One Minute Data Report</h5>                  
                  </div>               
                </Link>
              </div>            
            </div>
          </div>
        </div>
      </div>
      {/*<!-- Main Container End-->*/}
      </>
  );
}

export { ReportMenu, InverterDailyReport, SmbDailyReport, WmsDailyReport, MonthlyReport };
