import React, { useEffect, useMemo, useState, useReducer } from 'react';
import './App.css';
import { Routes, Route } from "react-router-dom";
import { Dashboard, AdminDashboard } from './screens/dashboard.js';
import { Inverter } from './screens/inverter.js';
import { SMB } from './screens/smb.js';
import { ReportMenu, MonthlyReport, InverterDailyReport, SmbDailyReport, WmsDailyReport } from './screens/report.js';
import { Parks } from './screens/parks.js';
import { Plants } from './screens/plants.js';
import { Inverters } from './screens/inverters.js';
import { Smbs } from './screens/smbs.js';
import { Mfms } from './screens/mfms.js';
import { Users } from './screens/users.js';
import { UserPlants } from './screens/userplants.js';
import { Alarm } from './screens/alarm.js';
import { NotFound } from './screens/404.js';
import { Login } from './screens/login.js';
import { AuthContext, PlantContext } from './utils/constants.js';
import { AdminTopBar, AdminSideNav } from './partials/layout.js';

function App() {
  const [sidebarExpand, setSidebarExpand] = useState(true);
  const collapseSideNav = ()=> {
    setSidebarExpand(!sidebarExpand);
  }

  const setCurrentPlant = (newPlant) => {
    setPlant({
      id: newPlant.id,
      name: newPlant.name,
      inverters: newPlant.inverters,
      smbs: newPlant.smbs, 
      mfms: newPlant.mfms,
      setCurrentPlant: plant.setCurrentPlant
    });
  }
  const [plant, setPlant] = useState({
    id: "",
    name: "",
    inverters: 0,
    smbs: 0,
    mfms: 0,    
    setCurrentPlant: setCurrentPlant
  });
  

  const [state, dispatch] = useReducer(
    (prevState, action) => {
      switch (action.type) {
        case 'RESTORE_TOKEN':
          return {
            ...prevState,
            userToken: action.token,
            isAdmin : action.is_admin,
            isLoading: false,
          };
        case 'SIGN_IN':
          return {
            ...prevState,
            isSignout: false,
            userToken: action.token,
            isAdmin : action.is_admin,
          };
        case 'SIGN_OUT':
          return {
            ...prevState,
            isSignout: true,
            userToken: null,
            isAdmin : false,
          };
      }
    },
    {
      isLoading: true,
      isSignout: false,
      userToken: null,
    }
  );

  useEffect(() => {
    // Fetch the token from storage then navigate to our appropriate place
    const bootstrapAsync = async () => {
      let userToken;
      let isAdminUser;

      try {
        userToken = await sessionStorage.getItem('userToken');
        isAdminUser = await sessionStorage.getItem('isAdminUser');
      } catch (e) {
        console.log(e);
      }
      dispatch({ type: 'RESTORE_TOKEN', token: userToken, is_admin:isAdminUser==="true"?true:false });
    };

    const retrivePlantState = async () => {
      try {
        const savedPlant = await sessionStorage.getItem('plantState');      
        setCurrentPlant(savedPlant?JSON.parse(savedPlant):null);
      } catch (e) {
        console.log(e);
      }                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
    }

    bootstrapAsync();
    retrivePlantState();
  }, []);

  const authContext = useMemo(
    () => ({
      signIn: async (data) => {

        fetch('/login', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            'username' :data.username,
            'password' :data.password
          })
        })
        .then(response => response.json())
        .then((result) => {
            sessionStorage.setItem("userToken", result.email);
            sessionStorage.setItem("userName", result.username);
            sessionStorage.setItem("isAdminUser", result.is_admin?true:false);
            dispatch({ type: 'SIGN_IN', token: result.email, is_admin:result.is_admin?true:false });
          })
          .catch((error) => {
            console.log(error);
            window.location.reload()
          });
      },
      signOut: async() => {
        sessionStorage.removeItem("userToken");
        sessionStorage.removeItem("isAdminUser");
        dispatch({ type: 'SIGN_OUT', token: null, is_admin: false});
      },
      signUp: async (data) => {
        // In a production app, we need to send user data to server and get a token
        // We will also need to handle errors if sign up failed
        // After getting token, we need to persist the token using `SessionStorage`
        // In the example, we'll use a dummy token
        dispatch({ type: 'SIGN_IN', token: 'dummy-auth-token' });
      },
    }),
    []
  );

  return (
    <AuthContext.Provider value={authContext}>
      <div className="App">
        {state.isLoading?(
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Dashboard />} />
          </Routes>
        ):state.userToken===null?(
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Dashboard />} />
          </Routes>
        ):state.isAdmin?(
          <div id="main-body">
            <AdminTopBar collapseSideNav={collapseSideNav} sidebarExpand={sidebarExpand} />
            <section id="FullPannel">
              <AdminSideNav sidebarExpand={sidebarExpand} />
              <section id="RightPannel">
                <main id="Content" className="container-fluid">
                  <Routes>
                    <>
                      <Route path="/" element={<AdminDashboard />} />
                      <Route path="/parks" element={<Parks />} />
                      <Route path="/plants" element={<Plants />} />
                      <Route path="/inverters" element={<Inverters />} />
                      <Route path="/smbs" element={<Smbs />} />
                      <Route path="/mfms" element={<Mfms />} />
                      <Route path="/users" element={<Users />} />
                      <Route path="/userplants" element={<UserPlants />} />
                      <Route path="/login" element={<Login />} />
                      <Route path="*" element={<NotFound />} />
                    </>
                  </Routes>
                </main>
              </section>
            </section>
          </div>
        ):(
          <PlantContext.Provider value={plant}>
            <Routes>(
              <>
                <Route path="/" element={<Dashboard />} />
                <Route path="/inverter" element={<Inverter />} />
                <Route path="/smb" element={<SMB />} />
                <Route path="/report" element={<ReportMenu />} />
                <Route path="/monthly-report" element={<MonthlyReport />} />
                <Route path="/inverter-daily-report" element={<InverterDailyReport />} />
                <Route path="/smb-daily-report" element={<SmbDailyReport />} />
                <Route path="/wms-daily-report" element={<WmsDailyReport />} />
                <Route path="/alarm" element={<Alarm />} />
                <Route path="/login" element={<Login />} />
                <Route path="*" element={<NotFound />} />
              </>
            )
            </Routes>
          </PlantContext.Provider>
        )}

      </div>
    </AuthContext.Provider>
  )
}

export default App;
