import { Component } from 'react';
import { postData, updateData, deleteItem } from '../utils/constants.js';

class Parks extends Component {
  constructor(props){
    super(props);
    this.state = {
      data : [],
      isLoading : true,
      show : false
    };
    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
    this.delete = this.delete.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async getParks(){
    try {
      const response = await fetch('/api/parks');
      const json = await response.json();
      this.setState({ data:json });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  add() {
    this.setState({
      id: "",
      name: "",
      address: "",
      is_active: 1,
      show: true
    });
  }

  edit(e) {
    const id = parseInt(e.target.id);
    const parks = this.state.data;
    const selectedPark = parks.filter(e=> e.id === id)[0];
    this.setState(selectedPark);
    this.setState({ show: true});
  }

  delete(e) {
    const id = parseInt(e.target.id);
    deleteItem("/api/park/"+id)
    .then((response) => {
      console.log(response);
      this.getParks();
    });
  }

  componentDidMount() {
    this.getParks();
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = this.state;
    this.setState({ show: false, isLoading: true });
    delete data.data;
    delete data.isLoading;
    if(data.id){
      updateData('/api/park/'+data.id, data)
        .then((response) => {
          console.log(response);
          this.getParks();
      });
    }else{
      postData('/api/park', data)
        .then((response) => {
          console.log(response);
          this.getParks();
      });
    }
  }

  render() {
    if(!this.state.isLoading) {
      return(
        <>
            <button className="btn btn-sm btn-primary float-end fw-bold mb-1" onClick={this.add}>
              <i className="fa-solid fa-plus"></i> Add Park
            </button>
            <div className="row">
              <div className="col-sm-12 col-md-8">
                <ParkList data={this.state.data} edit={this.edit} delete={this.delete} />
              </div>
              <div className="col-sm-12 col-md-4">
                {this.state.show &&
                  <>
                    <div className="park-form p-3 bg-light shadow-sm rounded">
                      <h4 className="mb-3 fw-bold"> {this.state.id?"Edit Park":"New Park"} </h4>
                      <form onSubmit={this.handleSubmit}>
                        <fieldset id="park-from" className="my-0 mx-auto">
                          <div className="row mb-3">
                            <div className="col-xs-12 col-md-12">
                              <label htmlFor="name">Name</label>
                              <input type="text" name="name" className="form-control" maxLength="100" value={this.state.name} onChange={this.handleInputChange} autoComplete="off" required />
                            </div>
                          </div>                          
                          <div className="row mb-3">
                            <div className="col-xs-12 col-md-12">
                              <label htmlFor="address">Location</label>
                              <textarea name="address" className="form-control" rows="4" maxLength="100" value={this.state.address} onChange={this.handleInputChange} autoComplete="off" required ></textarea>
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-xs-12 col-md-12">
                              <label htmlFor="title">Status</label>
                              <select name="is_active" className="form-select" value={this.state.is_active} onChange={this.handleInputChange} required>
                                <option value="0"> Inactive </option>
                                <option value="1"> Active </option>
                              </select>
                            </div>
                          </div>
                          <div>
                            <input type="submit" className="btn btn-primary fw-bold" value="Submit" />
                            <button type="button" className="btn btn-outline-secondary fw-bold ms-3" onClick={()=> this.setState({show: false})}>Cancel</button>
                          </div>
                        </fieldset>
                      </form>
                    </div>
                  </>
                }
              </div>
            </div>
        </>
      );
    }
    else {
      return(
        <>Loading...</>
      );
    }
  }
}

const ParkList = (props)=> {
  const park_list = props.data.map((item, index)=>
    <tr key={item.id} id={item.id}>
        <td className="text-nowrap">{index+1}</td>
        <td className="text-nowrap">{item.name}</td>
        <td className="text-nowrap">{item.address}</td>
        <td className={item.is_active?"text-success":"text-danger"}>{item.is_active?"Active":"Inactive"}</td>
        <td><i id={item.id} className="fa-solid fa-pencil text-warning action-icons" onClick={props.edit}></i></td>
        <td><i id={item.id} className="fa-regular fa-trash-alt text-danger action-icons" onClick={props.delete}></i></td>
    </tr>
  );

  return(
    <div className="table-responsive bg-white p-2 shadow">
      <h5 className="mx-2 fw-bold">Parks</h5>
      <table className="table table-light table-striped table-hover">
        <thead>
            <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Address</th>
                <th>Status</th>
                <th></th>
                <th></th>
            </tr>
        </thead>
        <tbody>
          {park_list}
        </tbody>
      </table>
    </div>
  );
}

export { Parks };
