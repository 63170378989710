import { useContext, useEffect, useRef, useState } from "react";
import { Navigate } from "react-router-dom";
import { SideNav, Header, InvHeader } from '../partials/layout.js';
import { PlantContext } from '../utils/constants.js';
import { io } from "socket.io-client";
const moment = require("moment");

function SMB(){
  const strings14 = [1,2,3,4,5,6,7,8,9,10,11,12,13,14];
  const strings20 =  [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20];
  const plant = useContext(PlantContext);
  const [data, setData] = useState([]);
  const [smbs, setSmbs] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const isLoggedIn = sessionStorage.getItem("userToken");
  var strings = [];

  useEffect(()=>{
    const socket = io();

    socket.on("smbdata/dashboard/site/"+plant.id, (smbs) => {
      console.log("smbs", smbs, plant.id)
      setSmbs(smbs);
    });
    
    return () => {
      socket.disconnect();
    };
  },[]);

  if(isLoggedIn){
    console.log("plant", plant);
    if([10,11,12].includes(plant.id)){
      strings = strings14;
    }else{
      strings = strings20;
    }
    var tableHeader = strings.map((string,i) =>
      <th key={"string"+string} className="text-center">String {string}<br/>[A]</th>
    );
    
    var tableBody = smbs.map((smb,i)=>
    <tr key={"smb"+smb.smb}>
      <th scope="row" className="text-nowrap">SMB {smb.device_name?smb.device_name:smb.device_id}</th>
      {strings.map((string)=>
        <td key={smb.smb+"string"+string}>
          <input type="text" className={smb["f"+(string+5)]>0?"smb-input":"smb-input-red"} value={Number(smb["f"+(string+5)]).toFixed(2)} readOnly/>
        </td>
      )}
      <td key={"dcvoltage"+smb.smb}>
        <input type="text" className={smb.f5>0?"smb-input":"smb-input-red"} value={Number(smb.f5).toFixed(2)} readOnly/>
      </td>
    </tr>
    );
    return (
      <>
      <Header/>
      <div className="container-fluid main">
        <div className="row">
          <SideNav/>
          <div className="main">
            <InvHeader />
            <div className="container-fluid">
              <div className="table-responsive my-2">
                <table className="table align-middle table-bordered border-dark">
                  <thead>
                    <tr>
                      <th></th>
                      {tableHeader}
                      <th key="totalstring" className="text-center">Total [A]</th>
                    </tr>
                  </thead>
                  <tbody className="table-group-divider">
                    {tableBody}
                  </tbody>
                </table>
            </div>
            </div>
            </div>
            </div>
            </div>

      </>
    )

  }else{
    return (
      <></>
    );
  }
}

export { SMB };
