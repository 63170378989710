import { useContext, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { AuthContext } from '../utils/constants.js';

function Login() {
  const { signIn } = useContext(AuthContext);
  const isLoggedIn = sessionStorage.getItem("userToken");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e)=> {
    e.preventDefault();
    signIn({ username: username, password: password });
  }

  return(
    <>
    {!isLoggedIn?(
      <div id="login-container">
        <div id="login-area" className="row">
          <div className="col-md-6 login-logo-container bg-light">
            <div className="d-flex align-items-center image-container">
              <img src="assets/macsoft-logo.png" className="rounded mx-auto d-block" alt="Macsoft" width="200" height="200" />
            </div>
          </div>
          <div className="col-md-6 col-xs-12 login-form-container bg-light p-5">
            <form id="login-form" onSubmit={handleSubmit} autoComplete="off">
              <h3 className="text-center mb-3 fw-bold"> Login </h3>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
                <input type="email" className="form-control" id="exampleInputEmail1" value={username} onChange={(e)=>setUsername(e.target.value)} aria-describedby="emailHelp" required />
                <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                <input type="password" className="form-control" id="exampleInputPassword1" value={password} onChange={(e)=>setPassword(e.target.value)} required />
              </div>
              <div className="mb-3 form-check">
                <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                <label className="form-check-label" htmlFor="exampleCheck1">Remember me</label>
              </div>
              <div className="mb-3">
                <Link className="text-dark fw-bold" to="#">Forget Password?</Link>
              </div>
              <button type="submit" className="btn btn-primary mt-1">Submit</button>
            </form>
          </div>
        </div>
      </div>
    ):(
      <Navigate to="/" />
    )}
    </>
  );
}

export { Login };
