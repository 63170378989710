import { Component } from 'react';
import { postData, updateData, deleteItem } from '../utils/constants.js';

class Mfms extends Component {
  constructor(props){
    super(props);
    this.state = {
      data : [],
      isLoading : true,
      show : false
    };
    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
    this.delete = this.delete.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async getMfms(){
    try {
      const response = await fetch('/api/mfms');
      const json = await response.json();
      this.setState({ data:json });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  add() {
    this.setState({
      id: "",
      device_id: "",
      bus_address: "",
      is_active: 1,
      show: true
    });
  }

  edit(e) {
    const id = parseInt(e.target.id);
    const mfms = this.state.data;
    const selectedMfm = mfms.filter(e=> e.id === id)[0];
    this.setState(selectedMfm);
    this.setState({ show: true});
  }

  delete(e) {
    const id = parseInt(e.target.id);
    deleteItem("/api/mfm/"+id)
    .then((response) => {
      console.log(response);
      this.getMfms();
    });
  }

  componentDidMount() {
    this.getMfms();
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = this.state;
    this.setState({ show: false, isLoading: true });
    delete data.data;
    delete data.isLoading;
    if(data.id){
      updateData('/api/mfm/'+data.id, data)
        .then((response) => {
          console.log(response);
          this.getMfms();
      });
    }else{
      postData('/api/mfm', data)
        .then((response) => {
          console.log(response);
          this.getMfms();
      });
    }
  }

  render() {
    if(!this.state.isLoading) {
      return(
        <>
          <div className="row">
            <div className="col-sm-12 col-md-8">
              <button className="btn btn-sm btn-primary fw-bold mb-1" onClick={this.add}>
                <i className="fa-solid fa-plus"></i> Add Mfm
              </button>
              <MfmList data={this.state.data} edit={this.edit} delete={this.delete} />
            </div>
            <div className="col-sm-12 col-md-4">
              {this.state.show &&
                <div className="mfm-form p-3 bg-light shadow-sm rounded">
                  <h4 className="mb-3 fw-bold"> {this.state.id?"Edit Mfm":"New Mfm"} </h4>
                  <form onSubmit={this.handleSubmit}>
                    <fieldset id="mfm-from" className="my-0 mx-auto">
                      <div className="row mb-3">
                        <div className="col-xs-12 col-md-12">
                          <label htmlFor="device_id">Device ID</label>
                          <input type="text" name="device_id" className="form-control" maxLength="50" value={this.state.ip_address} onChange={this.handleInputChange} required />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-xs-12 col-md-12">
                          <label htmlFor="bus_address">BUS Address</label>
                          <input type="text" name="bus_address" className="form-control" maxLength="100" value={this.state.bus_address} onChange={this.handleInputChange} required />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-xs-12 col-md-12">
                          <label htmlFor="title">Status</label>
                          <select name="is_active" className="form-select" value={this.state.is_active} onChange={this.handleInputChange} required>
                            <option value="0"> Inactive </option>
                            <option value="1"> Active </option>
                          </select>
                        </div>
                      </div>
                      <div>
                        <input type="submit" className="btn btn-primary fw-bold" value="Submit" />
                        <button type="button" className="btn btn-outline-secondary fw-bold ms-3" onClick={()=> this.setState({show: false})}>Cancel</button>
                      </div>
                    </fieldset>
                  </form>
                </div>
              }
            </div>
          </div>
        </>
      );
    }
    else {
      return(
        <>Loading...</>
      );
    }
  }
}

const MfmList = (props)=> {
  const mfm_list = props.data.map((item)=>
    <tr key={item.id} id={item.id}>
        <td className="text-nowrap">{item.device_id}</td>
        <td className="text-nowrap">{item.bus_address}</td>
        <td className={item.is_active?"text-success":"text-danger"}>{item.is_active?"Active":"Inactive"}</td>
        <td><i id={item.id} className="fa-solid fa-pencil text-warning action-icons" onClick={props.edit}></i></td>
        <td><i id={item.id} className="fa-regular fa-trash-alt text-danger action-icons" onClick={props.delete}></i></td>
    </tr>
  );

  return(
    <div className="table-responsive bg-white p-2 shadow">
      <h5 className="mx-2 fw-bold">MFM. </h5>
      <table className="table table-light table-striped table-hover">
        <thead>
            <tr>
                <th>Device ID</th>
                <th>Bus Address</th>
                <th>Status</th>
                <th></th>
                <th></th>
            </tr>
        </thead>
        <tbody>
          {mfm_list}
        </tbody>
      </table>
    </div>
  );
}

export { Mfms };
