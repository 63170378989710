import { Component } from 'react';
import { postData, updateData, deleteItem } from '../utils/constants.js';

class Users extends Component {
  constructor(props){
    super(props);
    this.state = {
      data : [],
      isLoading : true,
      show : false,
      show_content : ""
    };
    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
    this.view = this.view.bind(this);
    this.delete = this.delete.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async getUsers(){
    try {
      const response = await fetch('/api/users');
      const json = await response.json();
      this.setState({ data:json });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  add() {
    this.setState({
      id: "",
      username: "",
      email: "",
      password: "",
      user_role: "",
      is_admin: 0,
      is_active: 1,
      show: true,
      show_content: "form"
    });
  }

  view(e) {
    const id = parseInt(e.target.id);
    const users = this.state.data;
    const selectedUser = users.filter(e=> e.id === id)[0];
    this.setState(selectedUser);
    this.setState({ show: true, show_content: "view" });
  }

  edit(e) {
    const id = parseInt(e.target.id);
    const users = this.state.data;
    const selectedUser = users.filter(e=> e.id === id)[0];
    this.setState(selectedUser);
    this.setState({ show: true, show_content: "form" });
  }

  delete(e) {
    const id = parseInt(e.target.id);
    deleteItem("/api/user/"+id)
    .then((response) => {
      console.log(response);
      this.getUsers();
    });
  }

  componentDidMount() {
    this.getUsers();
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = this.state;
    this.setState({ show: false, isLoading: true });
    delete data.data;
    delete data.isLoading;
    if(data.id){
      updateData('/api/user/'+data.id, data)
        .then((response) => {
          console.log(response);
          this.getUsers();
      });
    }else{
      postData('/api/user', data)
        .then((response) => {
          console.log(response);
          this.getUsers();
      });
    }
  }

  render() {
    if(!this.state.isLoading) {
      const data = this.state.data;
      return(
        <>
          {!this.state.show?(
            <>
              <button className="btn btn-sm btn-primary fw-bold mb-1" onClick={this.add}>
                <i className="fa-solid fa-plus"></i> Add User
              </button>
              <UserList data={data} view={this.view} edit={this.edit} delete={this.delete} />
            </>
          ):(
            <>
              {this.state.show_content==="form"?(
                <>
                  <div className="user-form p-3 bg-light shadow-sm rounded">
                    <h4 className="mb-3 fw-bold"> New User </h4>
                    <form onSubmit={this.handleSubmit}>
                      <fieldset id="user-from" className="my-0 mx-auto">
                        <div className="row mb-3">
                          <div className="col-xs-12 col-md-6">
                            <label htmlFor="username">Name</label>
                            <input type="text" name="username" className="form-control" maxLength="100" value={this.state.username} onChange={this.handleInputChange} required />
                          </div>
                          <div className="col-xs-12 col-md-6">
                            <label htmlFor="email">Email</label>
                            <input type="email" name="email" className="form-control" maxLength="50" value={this.state.email} onChange={this.handleInputChange} required />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-xs-12 col-md-6">
                            <label htmlFor="password">Password</label>
                            <input type="password" name="password" className="form-control" maxLength="16" value={this.state.password} onChange={this.handleInputChange} required />
                          </div>
                          <div className="col-xs-12 col-md-6">
                            <label htmlFor="user_role">User Role</label>
                            <select name="user_role" className="form-select" value={this.state.user_role} onChange={this.handleInputChange} required>
                              <option value=""> -- Select Role -- </option>
                              <option value="admin">Admin</option>
                              <option value="supervisor">Supervisor</option>
                            </select>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-xs-12 col-md-6">
                            <label htmlFor="is_admin">Is Admin</label>
                            <select name="is_admin" className="form-select" value={this.state.is_admin} onChange={this.handleInputChange} required>
                              <option value={1}> Yes </option>
                              <option value={0}> No </option>
                            </select>
                          </div>
                          <div className="col-xs-12 col-md-6">
                            <label htmlFor="title">Status</label>
                            <select name="is_active" className="form-select" value={this.state.is_active} onChange={this.handleInputChange} required>
                              <option value="0"> Inactive </option>
                              <option value="1"> Active </option>
                            </select>
                          </div>
                        </div>
                        <div>
                          <input type="submit" className="btn btn-primary fw-bold" value="Submit" />
                          <button type="button" className="btn btn-outline-secondary fw-bold ms-3" onClick={()=> this.setState({show: false})}>Cancel</button>
                        </div>
                      </fieldset>
                    </form>
                  </div>
                </>
              ):(
                <ViewUser data={this.state} close={()=> this.setState({show: false})} />
              )}
            </>
          )}
        </>
      );
    }
    else {
      return(
        <>Loading...</>
      );
    }
  }
}

const UserList = (props)=> {
  const user_list = props.data.map((item)=>
    <tr key={item.id} id={item.id}>
        <td className="text-nowrap">{item.username}</td>
        <td>{item.user_role}</td>
        <td>{item.email}</td>
        <td className={item.is_active?"text-success":"text-danger"}>{item.is_active?"Active":"Inactive"} </td>
        <td><i id={item.id} className="fa-solid fa-circle-info text-primary action-icons" onClick={props.view}></i></td>
        <td><i id={item.id} className="fa-solid fa-pencil text-warning action-icons" onClick={props.edit}></i></td>
        <td><i id={item.id} className="fa-regular fa-trash-alt text-danger action-icons" onClick={props.delete}></i></td>
    </tr>
  );
  return(
    <div className="table-responsive bg-white p-2 shadow">
      <h5 className="mx-2 fw-bold">Users</h5>
      <table className="table table-light table-striped table-hover">
        <thead>
            <tr>
                <th>Name</th>
                <th>Role</th>
                <th>Email</th>
                <th>Status</th>
                <th></th>
                <th></th>
                <th></th>
            </tr>
        </thead>
        <tbody>
          {user_list}
        </tbody>
      </table>
    </div>
  );
}

const ViewUser = (props)=> {
  return (
    <div className="p-3 bg-light shadow-sm rounded">
      <h4 className="mb-3 fw-bold"> {props.data.title} </h4>
      <div className="row">
        <div className="col-md-6 mb-3">
          <p className="mb-1">Username</p>
          <h6>{props.data.username}</h6>
        </div>
        <div className="col-md-6 mb-3">
          <p className="mb-1">Role</p>
          <h6>{props.data.user_role}</h6>
        </div>
        <div className="col-md-6 mb-3">
          <p className="mb-1">Email</p>
          <h6>{props.data.email}</h6>
        </div>
        <div className="col-md-6 mb-3">
          <p className="mb-1">Status</p>
          <h6 className={props.data.is_active?"text-success":"text-danger"}>{props.data.is_active?"Active":"Inactive"}</h6>
        </div>
        <div className="col-md-6 mb-3">
          <p className="mb-1">Is Admin</p>
          <h6>{props.data.is_admin?"Yes":"No"}</h6>
        </div>
        <div>
          <button className="btn btn-secondary fw-bold" onClick={props.close}>Close</button>
        </div>
      </div>
    </div>
  );
}

export { Users };
