import { Component } from 'react';
import { postData, updateData, deleteItem } from '../utils/constants.js';

class UserPlants extends Component {
  constructor(props){
    super(props);
    this.state = {
      data : [],
      users: [],
      plants: [],
      isLoading : true,
      show : false
    };
    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
    this.delete = this.delete.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async getUsers(){
    try{
      const response = await fetch('/api/users');
      const json = await response.json();
      this.setState({ users:json });
    }catch(error){
      console.log(error);
    }finally{
      this.setState({isLoading : false })
    }
  }

  async getPlants(){
    try{
      const response = await fetch('/api/plants');
      const json = await response.json();
      this.setState({ plants:json });
    }catch(error){
      console.log(error);
    }finally{
      this.setState({isLoading : false })
    }
  }


  async getUserPlants(){
    try {
      const response = await fetch('/api/userplants');
      const json = await response.json();
      this.setState({ data:json });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  add() {
    this.setState({
      id: "",
      user_name: "",
      plant_name: "",
      is_active: 1,
      show: true
    });
  }

  edit(e) {
    const id = parseInt(e.target.id);
    const userplants = this.state.data;
    const selectedUserPlant = userplants.filter(e=> e.id === id)[0];
    this.setState(selectedUserPlant);
    this.setState({ show: true});
  }

  delete(e) {
    const id = parseInt(e.target.id);
    deleteItem("/api/userplant/"+id)
    .then((response) => {
      console.log(response);
      this.getUserPlants();
    });
  }

  componentDidMount() {
    this.getUsers();
    this.getPlants();
    this.getUserPlants();
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = this.state;
    if(data.id){
      updateData('/api/userplant/'+data.id, data)
        .then((response) => {
          this.getUserPlants();
      });
    }else{
      postData('/api/userplant', data)
        .then((response) => {
          this.getUserPlants();
      });
    }
  }

  render() {
    if(!this.state.isLoading) {
      return(
        <>
            <div className="row">
              <div className="col-sm-12 col-md-8">
                <UserPlantList data={this.state.data} add={this.add} edit={this.edit} delete={this.delete} />
              </div>
              <div className="col-sm-12 col-md-4">
                {this.state.show &&
                  <>
                    <div className="userplant-form p-3 bg-light shadow-sm rounded">
                      <h4 className="mb-3 fw-bold"> {this.state.id?"Edit UserPlant":"New UserPlant"} </h4>
                      <form onSubmit={this.handleSubmit}>
                        <fieldset id="userplant-from" className="my-0 mx-auto">
                          <div className="row mb-3">
                            <div className="col-xs-12 col-md-12">
                              <label htmlFor="user_name">User Name</label>
                              <UserList data={this.state.users} change={this.handleInputChange} />
                            </div>
                          </div>                          
                          <div className="row mb-3">
                            <div className="col-xs-12 col-md-12">
                              <label htmlFor="plant_name">Plant Name</label>
                              <PlantList data={this.state.plants} change={this.handleInputChange} />
                            </div>
                          </div>
                          <div>
                            <input type="submit" className="btn btn-primary fw-bold" value="Submit" />
                            <button type="button" className="btn btn-outline-secondary fw-bold ms-3" onClick={()=> this.setState({show: false})}>Cancel</button>
                          </div>
                        </fieldset>
                      </form>
                    </div>
                  </>
                }
              </div>
            </div>
        </>
      );
    }
    else {
      return(
        <>Loading...</>
      );
    }
  }
}

const UserPlantList = (props)=> {
  const userplant_list = props.data.map((item, index)=>
    <tr key={item.id} id={item.id}>
        <td className="text-nowrap">{index+1}</td>
        <td className="text-nowrap">{item.username}&nbsp;({item.useremail})</td>
        <td className="text-nowrap">{item.plant_name}</td>
        <td><i id={item.id} className="fa-solid fa-pencil text-warning action-icons" onClick={props.edit}></i></td>
        <td><i id={item.id} className="fa-regular fa-trash-alt text-danger action-icons" onClick={props.delete}></i></td>
    </tr>
  );

  return(
    <div className="bg-white p-2 shadow">
      <div className="row">
        <div className="col">
          <h5 className="mx-2 fw-bold">User Plants</h5>
        </div>
        <div className="col">
          <button className="btn btn-sm btn-primary float-end fw-bold mb-1" onClick={props.add}>
            <i className="fa-solid fa-plus"></i> Add UserPlant
          </button>
        </div>
      </div>
      <table className="table table-light table-striped table-hover">
        <thead>
            <tr>
                <th>S.No</th>
                <th>User</th>
                <th>Plant</th>
                <th></th>
                <th></th>
            </tr>
        </thead>
        <tbody>
          {userplant_list}
        </tbody>
      </table>
    </div>
  );
}

const UserList = (props) => {
  const user_list = props.data.map( (item) => 
    <option key={item.id} value={item.id}>{item.username}&nbsp;({item.email})</option>
  );

  return (
    <select name="user_name" className="form-control"  onChange={props.change} required >
      <option key="0" value="">Select User</option>
      {user_list}
    </select>
  )
}

const PlantList = (props) => {
  const plant_list = props.data.map( (item) => 
    <option key={item.id} value={item.id}>{item.name}</option>
  );

  return (
    <select name="plant_name" className="form-control"  onChange={props.change} required >
      <option key="0" value="">Select Plant</option>
      {plant_list}
    </select>
  )
}

export { UserPlants };
