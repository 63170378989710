import { Component } from 'react';
import { SideNav, Header } from '../partials/layout.js';
import moment from 'moment';

class Alarm extends Component {
  constructor(props){
    super(props);
    this.state = {
      data       : [],
      fromDate   : moment().format(moment.HTML5_FMT.DATE),
      toDate     : moment().format(moment.HTML5_FMT.DATE),
      alarmType  : "",
      deviceType : "",
      isLoading  : true
    };
    this.getAlarms = this.getAlarms.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(e){
    var name = e.target.name;
    var value = e.target.value
    if(e.target.type==="date"){
      value = moment(value).format("YYYY-MM-DD");
    }
    this.setState({
      [name]: value
    });
  }

  async getAlarms(){
    try {
      const response = await fetch(`/api/alarms?fromDate=${this.state.fromDate}&toDate=${this.state.toDate}&alarmType=${this.state.alarmType}&deviceType=${this.state.deviceType}`);
      const json = await response.json();
      this.setState({ data:json });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  componentDidMount() {
    this.getAlarms();
  }

  render() {
    if(!this.state.isLoading) {
      const alarm_list = this.state.data.map((item)=>
        <tr key={item.id} id={item.id}>
            <td className="text-nowrap">{item.alarm_date}</td>
            <td className="text-nowrap">{item.alarm_type}</td>
            <td className="text-nowrap">{item.device_type}</td>
            <td className="text-nowrap">{item.device_id}</td>
            <td className={item.alarm_type==="error"?"text-danger":item.alarm_type==="warning"?"text-warning":"text-info"}>{item.message}</td>
        </tr>
      );
      return(
        <>
          <Header />
          <div className="container-fluid main">
            <div className="row">
              <SideNav />
              <div className="main">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-11">
                      <div className="input-group mb-3 align-items-center">
                        <label className="me-2" htmlFor="fromDate">From Date:</label>
                        <input type="date" className="form-control" name="fromDate" value={this.state.fromDate} onChange={this.handleInputChange} />
                        <label className="ms-3 me-2" htmlFor="fromDate">To Date:</label>
                        <input type="date" className="form-control" name="toDate" value={this.state.toDate} onChange={this.handleInputChange} />
                        <label className="ms-3 me-2" htmlFor="alarmType">Alarm Type:</label>
                        <select className="form-select" name="alarmType" value={this.state.alarmType} onChange={this.handleInputChange}>
                          <option value=""> -- All -- </option>
                          <option value="error"> Error </option>
                          <option value="warning"> Warning </option>
                          <option value="info"> Info </option>
                        </select>
                        <label className="ms-3 me-2" htmlFor="deviceType">Device Type:</label>
                        <select className="form-select" name="deviceType" value={this.state.deviceType} onChange={this.handleInputChange}>
                          <option value=""> -- All -- </option>
                          <option value="inverter"> Inverter </option>
                          <option value="smb"> SMB </option>
                          <option value="mfm"> MFM </option>
                        </select>
                        <button type="button" className="btn btn-secondary" id="search" onClick={this.getAlarms}>Search</button>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive bg-white p-2 shadow-sm">
                    <h5 className="mx-2 fw-bold">Alarm.</h5>
                    <table className="table table-light table-striped table-hover">
                      <thead>
                          <tr>
                              <th>Date</th>
                              <th>Alarm Type</th>
                              <th>Device Type</th>
                              <th>Device ID</th>
                              <th>Message</th>
                          </tr>
                      </thead>
                      <tbody>
                        {alarm_list}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
    else {
      return(
        <>Loading...</>
      );
    }
  }
}

export { Alarm };
