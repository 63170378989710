import { useContext, useEffect, useRef, useState } from "react";
import { Navigate } from "react-router-dom";
import { SideNav, Header, InvHeader } from '../partials/layout.js';
import { PlantContext } from '../utils/constants.js';
import { io } from "socket.io-client";
import { Chart, getDatasetAtEvent, getElementAtEvent, getElementsAtEvent } from 'react-chartjs-2';
import moment from 'moment';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarController, BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarController, BarElement,
  Title,
  Tooltip,
  Legend
);

function BarChart(props) {
  const plant = useContext(PlantContext);
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({datasets:[]});
  const [isLoading, setLoading] = useState(true);

  const options = {
    responsive: true,
    events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Power (kW)'
      },
      tooltip: {
        usePointStyle: true,
        events: ['click', 'mousemove']
      }
    },
  };

  const getHourlyData = (inverter_data, irradiation_data)=> {
    try {
      delete inverter_data.created_date; 
      delete inverter_data.plant_id;
      delete inverter_data.rdate;
      delete inverter_data["1am"];
      delete inverter_data.inverter_id;
      var labelsOrg = Object.keys(inverter_data); 

      var labels = [];
      labelsOrg.map(a=>{
        if (inverter_data[a] !== null){
          labels.push(a);
        }
      });
      var InvValues = labels.map(o=> inverter_data[o]);
      var IrrValues = [];
      if(Object.keys(irradiation_data).length){
        IrrValues = labels.map(o=> irradiation_data[o]);
      }
      var data = {
        labels : labels,
        datasets: [
          {
            label: "Power",
            backgroundColor: "rgb(255, 99, 132)",
            borderColor: "rgb(255, 99, 132)",
            data: InvValues,
          },
          {
            label: "Irradiation",
            backgroundColor: "rgb(128, 217, 199)",
            borderColor: "rgb(128, 217, 199)",
            data: IrrValues,
          }
        ]
      }
      setChartData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const socket = io();
    const chart = chartRef.current;
    socket.on("hourlydata/inverter/" + props.inverterid, (result) => {
      getHourlyData(result.hourly_data, result.irradiation_data);
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  return(
    <>
    {!isLoading?(
        <Chart
          ref={chartRef}
          type='bar'
          data={chartData}
          options={options}
          onClick={(event) => {
            const dataset = getDatasetAtEvent(chartRef.current, event);
            const element = getElementAtEvent(chartRef.current, event);
            const elements = getElementsAtEvent(chartRef.current, event);
            console.log("dataset", dataset);
            console.log("element", element);
            console.log("elements", elements);
          }}
        />
      ):(
         <></>
      )
    }
    </>
  );
}

function Inverter(){
  const plant = useContext(PlantContext);
  const [inverterData, setInverterData] = useState([]);
  const [inverterStatus, setInverterStatus] = useState([]);
  const [wmsData, setWmsData] = useState({});
  const isLoggedIn = sessionStorage.getItem("userToken");

  useEffect(()=>{
    const socket = io();
    
    socket.on("inverterdata/plant/" + plant.id, (result) => {
      setInverterData(result.inverterData);
    });

    socket.on("wms_data/plant/"+ plant.id, (result)=> {
	    setWmsData(result);
    });

    socket.on("inverterstatus/plant/" + plant.id, (result) => {
      setInverterStatus(result.inverterStatus);
    });
    return () => {
      socket.disconnect();
    };
  },[]);

  if(isLoggedIn && inverterData){
    var TotalKWH = 0;
    var TotalKVAh = 0;
    var TodayKWH = 0;
    var TodayKVAh = 0;
    var Co2Reduction = 0;
    var GridDowntime = "";
    var OperatingHrs = "";
    var StartTime = "";
    var EndTime = "";
    var Availability = 0;

    inverterData.map((a,i)=>{
      a.id = i;
      return a;
    });    

    inverterData.forEach((data)=>{
      TotalKWH += Number(data.TotalKWH);
      TotalKVAh += Number(data.TotalKVAh);
      TodayKWH += Number(data.TodayKWH);
      TodayKVAh += Number(data.TodayKVAh);
      Co2Reduction += (Number(data.TotalKWH) * (9/10000));
      if(isNaN(Co2Reduction)===false){
        Co2Reduction = Number(Co2Reduction.toFixed(2));
      }

    });
    if(inverterData.length>0){      
      OperatingHrs = Number(inverterData[0].OperatingHrs);
      GridDowntime = Number(inverterData[0].GridDowntime);
      StartTime = Number(inverterData[0].StartTime);
      EndTime = Number(inverterData[0].EndTime);
      Availability = (100*(EndTime-StartTime-GridDowntime)/(EndTime-StartTime));
    }

    return (
      <>
      {/*<!-- Header-->*/}
      <Header />
      {/*<!-- Header End-->*/}
      {/*<!-- Main Container-->*/}
      <div className="container-fluid main">
        <div className="row">
          <SideNav />
          <div className="main">
            <InvHeader />
            <div className="container-fluid">
              <div className="pt-4 pl_oper">
                <h6 className="mb-0 text-center">PLANT OPERATION</h6>
              </div>
              {/* Plant Operations */}
              <div id="plant-area" className="row">
                <div className="col-lg-3 col-md-6 col-xs-12 col-sm-6 plant-container plant-wrapper mt-3">
                  <div className="pt-1">
                    <label className="col-md-9">Operating Hours </label>
                    <span className="col-md-3">{moment.utc(moment.duration(EndTime-StartTime).as('milliseconds')).format("HH:mm")}</span>
                  </div>
                  <div className="pt-2">
                    <label className="col-md-9">Grid Downtime </label>
                    <span className="col-md-3">{moment.utc(moment.duration(GridDowntime).as('milliseconds')).format("HH:mm")}</span>
                  </div>
                  <div className="pt-2">
                    <label className="col-md-9">Grid Availability </label>
                    <span className="col-md-3">{Availability.toFixed(2)}%</span>
                  </div>
                  <div className="pt-2">
                    <label className="col-md-9">Irradiation [W/sq.m]</label>
                    <span className="col-md-3">{wmsData.IrradiationValue?wmsData.IrradiationValue:""} </span>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-xs-12 col-sm-6 mt-3">
                  <div className="col plant-container plant-wrapper">
                    <div className="pt-1">
                      <label className="col-md-9">Plant Start [HH:MM] </label>
                      <span className="col-md-3 text-nowrap">{moment(StartTime).format("HH:m a")}</span>
                    </div>
                    <div className="pt-2">
                      <label className="col-md-9">Plant Stop [HH:MM] </label>
                      <span className="col-md-3 text-nowrap">{moment(EndTime).format("HH:m a")}</span>
                    </div>
                    <div className="pt-2">
                      <label className="col-md-9">PR RATIO </label>
                      <span className="col-md-3">0</span>
                    </div>
		                <div className="pt-2">
                      <label className="col-md-9">Today Irradiation Power [kWH]</label>
                      <span className="col-md-3">{wmsData.TodayIrrPower?wmsData.TodayIrrPower:""} </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-xs-12 col-sm-6 plant-container plant-wrapper mt-3">
                  <div className="pt-1">
                    <label className="col-md-9">Total Power Supplied [KWh]</label>
                    <span className="col-md-3">{TotalKWH}</span>
                  </div>
                  <div className="pt-2">
                    <label className="col-md-9">Total Energy Supplied [KVAh]</label>
                    <span className="col-md-3">{TotalKVAh}</span>
                  </div>
                  <div className="pt-2">
                    <label className="col-md-9">Monthly Export [MWh] </label>
                    <span className="col-md-3"></span>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-xs-12 col-sm-6 mt-3">
                  <div className="col plant-container plant-wrapper">
                    <div className="pt-1">
                      <label className="col-md-9">Daily Power Supplied [kWh] </label>
                      <span className="col-md-3">{TodayKWH}</span>
                    </div>
                    <div className="pt-2">
                      <label className="col-md-9">Daily Energy Supplied [KVAh] </label>
                      <span className="col-md-3">{TodayKVAh}</span>
                    </div>
                    <div className="pt-2">
                      <label className="col-md-9">CO2 Reduction </label>
                      <span className="col-md-3">{Co2Reduction} </span>
                    </div>
                  </div>
                </div>
              </div>
              {/* Inverter Operations */}
              {inverterData.map((data, index)=>                                  
                <div key={data.id} id="inverter-area" className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="pt-4 pl_oper">
                      <h6 className="mb-0 text-center">INVERTER {index+1} PARAMETERS</h6>
                    </div>
                    <div className="row">
                      <div className="col-lg-3 col-md-6 col-xs-12 col-sm-6 mt-3 plant-container plant-wrapper">
                        <div className="pt-1">
                          <label className="col-md-9">Active Power[kW] </label>
                          <span className="col-md-3">{data.ActivePower}</span>
                        </div>
                        <div className="pt-2">
                          <label className="col-md-9">Reactive Power[kVAr] </label>
                          <span className="col-md-3">{data.ReactivePower}</span>
                        </div>
                        <div className="pt-2">
                          <label className="col-md-9">DC Input Voltage[V] </label>
                          <span className="col-md-3">{data.DCVoltage}</span>
                        </div>
                        <div className="pt-2">
                          <label className="col-md-9">DC Input Current [A] </label>
                          <span className="col-md-3">{data.DCCurrent}</span>
                        </div>
                        <div className="pt-2">
                          <label className="col-md-9">Grid Current[A] </label>
                          <span className="col-md-3">{data.GridCurrent}</span>
                        </div>
                        <div className="pt-2">
                          <label className="col-md-9">Grid Voltage[V] </label>
                          <span className="col-md-3">{data.GridVoltage}</span>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-xs-12 col-sm-6 mt-3">
                        <div className="col plant-container plant-wrapper">
                          <div className="pt-1">
                            <label className="col-md-9">Grid Frequency </label>
                            <span className="col-md-3">{data.GridFrequency}</span>
                          </div>
                          <div className="pt-2">
                            <label className="col-md-9">Power Factor </label>
                            <span className="col-md-3">{data.PowerFactor}</span>
                          </div>
                          <div className="pt-2">
                            <label className="col-md-9">Inv Efficiency [%] </label>
                            <span className="col-md-3">{((data.GridCurrent/data.DCCurrent)*100).toFixed(2)}</span>
                          </div>
                          <div className="mt-1">
                            <label className="col-md-9">AB Line Voltage [V] </label>
                            <span className="col-md-3">{data.L1_L2Voltage}</span>
                          </div>
                          <div className="pt-2">
                            <label className="col-md-9">BC Line Voltage [V]</label>
                            <span className="col-md-3">{data.L2_L3Voltage}</span>
                          </div>
                          <div className="pt-2">
                            <label className="col-md-9">CA Line Voltage [V]</label>
                            <span className="col-md-3">{data.L3_L1Voltage}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-xs-12 col-sm-6 mt-3 plant-container plant-wrapper">
                        <div className="pt-1">
                          <label className="col-md-9">DC Power [kW]</label>
                          <span className="col-md-3">{Number((data.DCVoltage * data.DCCurrent)/1000).toFixed(2)}</span>
                        </div>
                        <div className="pt-2">
                          <label className="col-md-9">Grounding Current [A]</label>
                          <span className="col-md-3">0</span>
                        </div>
                        <div className="pt-2">
                          <label className="col-md-9">Isolation Resistance [kOhm] </label>
                          <span className="col-md-3">0</span>
                        </div>
                        <div className="pt-2">
                          <label className="col-md-9">Total Power Supplied [KWh]</label>
                          <span className="col-md-3">{data.TotalKWH}</span>
                        </div>
                        <div className="pt-2">
                          <label className="col-md-9">Total Energy Supplied [KVAh]</label>
                          <span className="col-md-3">{data.TotalKVAh}</span>
                        </div>
                        <div className="pt-2">
                          <label className="col-md-9">Peak Power Generated kW</label>
                          <span className="col-md-3">{data.PeakPower}</span>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-xs-12 col-sm-6 mt-3">
                        <div className="col plant-container plant-wrapper">
                          <div className="pt-1">
                            <label className="col-md-9">Ambient Temperature [c]</label>
                            <span className="col-md-3">{data.InverterTemperature}</span>
                          </div>
                          <div className="pt-2">
                            <label className="col-md-9">Control Section Temp [c]</label>
                            <span className="col-md-3">0</span>
                          </div>
                          <div className="pt-2">
                            <label className="col-md-9">Daily Power Supplied [kWh] </label>
                            <span className="col-md-3">{data.TodayKWH}</span>
                          </div>
                          <div className="pt-2">
                            <label className="col-md-9">Daily Energy Supplied [KVAh] </label>
                            <span className="col-md-3">{data.TodayKVAh}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>                       
              )}
              {inverterStatus.map((Inv, index)=> 
               <div key={"I"+Inv.device_reg_number} className="row my-2">
                {/* Inverter Status */}
                <div className="mt-2 col-xl-6 col-lg-6 col-12 p-0">
                  <div className="inverter-status">
                    <div className="pt-3 pb-3 text-center">
                      <h6>INVERTER {index+1} STATUS</h6>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="row">
                          <div className="col-lg-8 col-md-8 col-sm-8 mb-2">
                            <label>Stable/Unstable</label>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-4 is-status mb-2 text-align">
                            <label></label>
                          </div>

                          <div className="col-lg-8 col-md-8 col-sm-8 mb-2">
                            <label>Grid Connected</label>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-4 is-status mb-2">
                            <label></label>
                          </div>

                          <div className="col-lg-8 col-md-8 col-sm-8 mb-2">
                            <label className="text-nowrap">UnderVoltage Condition</label>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-4 is-status mb-2">
                            <label></label>
                          </div>

                          <div className="col-lg-8 col-md-8 col-sm-8 mb-2">
                            <label className="text-nowrap">OverVoltage Condition</label>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-4 is-status mb-2">
                            <label></label>
                          </div>

                          <div className="col-lg-8 col-md-8 col-sm-8 mb-2">
                            <label>Over Temperature</label>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-4 is-status mb-2">
                            <label></label>
                          </div>

                          <div className="col-lg-8 col-md-8 col-sm-8 mb-2">
                            <label>MV OverPressure</label>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-4 is-status mb-2">
                            <label></label>
                          </div>

                          <div className="col-lg-8 col-md-8 col-sm-8 mb-2">
                            <label>MV Low Oil Level</label>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-4 is-status mb-2">
                            <label></label>
                          </div>

                          <div className="col-lg-8 col-md-8 col-sm-8 mb-2">
                            <label>Excess humidity</label>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-4 is-status mb-2">
                            <label></label>
                          </div>

                          <div className="col-lg-8 col-md-8 col-sm-8 mb-2">
                            <label>Fan Fault</label>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-4 is-status mb-2">
                            <label></label>
                          </div>

                          <div className="col-lg-8 col-md-8 col-sm-8 mb-2">
                            <label>Door AC DC Fault</label>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-4 is-status mb-2">
                            <label></label>
                          </div>

                        </div>

                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="row">
                          <div className="col-lg-8 col-md-8 col-sm-8 mb-2">
                            <label>Fault</label>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-4 is-status mb-2">
                            <label className={Inv.isConnected?"":"bg-danger"}></label>
                          </div>
                          <div className="col-lg-8 col-md-8 col-sm-8 mb-2">
                            <label>Gnd Current Fault</label>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-4 is-status mb-2">
                            <label></label>
                          </div>
                          <div className="col-lg-8 col-md-8 col-sm-8 mb-2">
                            <label>Reverse Current Fault</label>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-4 is-status mb-2">
                            <label></label>
                          </div>
                          <div className="col-lg-8 col-md-8 col-sm-8 mb-2">
                            <label>DC OverCurrent Fault</label>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-4 is-status mb-2">
                            <label></label>
                          </div>
                          <div className="col-lg-8 col-md-8 col-sm-8 mb-2">
                            <label>AC OverCurrent Fault</label>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-4 is-status mb-2">
                            <label></label>
                          </div>
                          <div className="col-lg-8 col-md-8 col-sm-8 mb-2">
                            <label>AC Contactor Fault</label>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-4 is-status mb-2">
                            <label></label>
                          </div>
                          <div className="col-lg-8 col-md-8 col-sm-8 mb-2">
                            <label>DC Contactor Fault</label>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-4 is-status mb-2">
                            <label></label>
                          </div>
                          <div className="col-lg-8 col-md-8 col-sm-8 mb-2">
                            <label>IGBT OverTemp Fault</label>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-4 is-status mb-2">
                            <label></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Hourly Power Generation Graph */}
                <div className="mt-2 col-xl-6 col-lg-6 col-12 inverter-status is-margin">
                  <div className="pt-3 pb-3 text-center ">
                    <h6>Generation Day Hourly : kWh [Inverter {index+1}]</h6>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <BarChart inverterid={Inv.device_reg_number}/>
                    </div>
                  </div>
                </div>
              </div>                           
              )}    
            </div>
          </div>

        </div>
      </div>
      {/*<!-- Main Container End-->*/}
      </>
    )

  }else{
    return (
      <Navigate to="/login" />
    );
  }
}

export { Inverter };
