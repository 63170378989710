import { Component } from 'react';
import { postData, updateData, deleteItem } from '../utils/constants.js';

class Smbs extends Component {
  constructor(props){
    super(props);
    this.state = {
      data : [],
      parks: [],
      plants: [],
      inverters: [],
      isLoading : true,
      show : false
    };
    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
    this.delete = this.delete.bind(this);
    this.handleParkChange = this.handleParkChange.bind(this);
    this.handlePlantChange = this.handlePlantChange.bind(this);
    this.handleInverterChange = this.handleInverterChange.bind(this);    
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async getParks(){
    try {
      const response = await fetch('/api/parks');
      const json = await response.json();
      this.setState({ parks:json, data:[], isLoading: false });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async getPlants(id){
    try {
      const response = await fetch('/api/plants/'+id);
      const json = await response.json();
      this.setState({ plants:json, data:[], isLoading: false});
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }
  
  async getInverters(id){
    try {
      const response = await fetch('/api/inverters/'+id);
      const json = await response.json();
      this.setState({ inverters:json, data:[], isLoading: false });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async getSmbs(id){
    try {
      const response = await fetch('/api/smbs/'+id);
      const json = await response.json();
      this.setState({ data:json, show: false });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  add() {
    this.setState({
      id: "",
      device_id: "",
      serial_number: "",
      strings: 12,
      is_active: 1,
      show: true
    });
  }

  edit(e) {
    const id = parseInt(e.target.id);
    const smbs = this.state.data;
    const selectedSmb = smbs.filter(e=> e.id === id)[0];
    this.setState(selectedSmb);
    this.setState({ show: true});
  }

  delete(e) {
    const id = parseInt(e.target.id);
    deleteItem("/api/smb/"+id)
    .then((response) => {
      console.log(response);
      this.getSmbs(this.state.inverter_id);
    });
  }

  componentDidMount() {
    this.getParks();
  }

  handleParkChange(event) {
    const target = event.target;
    if(event.target.value){
      this.getPlants(event.target.value);
    }else{
      this.setState({ data:[] });
    }
  }

  handlePlantChange(event) {
    const target = event.target;
    if(target.value){
      this.setState({plant_id: target.value});
      this.getInverters(target.value);
    }else{
      this.setState({ data:[] });
    }
  }    

  handleInverterChange(event) {
    const target = event.target;
    if(target.value){
      this.setState({inverter_id: target.value});
      this.getSmbs(target.value);
    }else{
      this.setState({ data:[] });
    }
  }      

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = this.state;
    if(data.id){
      updateData('/api/smb/'+data.id, data)
        .then((response) => {
          this.getSmbs(this.state.inverter_id);
      });
    }else{
      postData('/api/smb', data)
        .then((response) => {
          this.getSmbs(this.state.inverter_id);
      });
    }
  }

  render() {
    if(!this.state.isLoading) {
      return(
        <>
          <div className="row g-0">
            <div className="col-sm-6 col-md-3">
              <ParkList data={this.state.parks} change={this.handleParkChange} />
            </div>
            <div className="col-sm-6 col-md-4">
              <PlantList data={this.state.plants} change={this.handlePlantChange} />
            </div>         
            <div className="col-sm-6 col-md-3">
              <InverterList data={this.state.inverters} change={this.handleInverterChange} />
            </div>                          
            <div className="col-sm-6 col-md-2">
              <button className="btn btn-sm btn-primary float-end fw-bold" onClick={this.add}>
                <i className="fa-solid fa-plus"></i> Add SMB
              </button>
            </div>
          </div> 
          <div className="row">
            <div className="col-sm-12 col-md-8">
              <SmbList data={this.state.data} edit={this.edit} delete={this.delete} />
            </div>
            <div className="col-sm-12 col-md-4">
              {this.state.show &&
                <div className="smb-form p-3 bg-light shadow-sm rounded">
                  <h4 className="mb-3 fw-bold"> {this.state.id?"Edit Smb":"New Smb"} </h4>
                  <form onSubmit={this.handleSubmit}>
                    <fieldset id="smb-from" className="my-0 mx-auto">
                      <div className="row mb-3">
                        <div className="col-xs-12 col-md-12">
                          <label htmlFor="device_id">Device ID</label>
                          <input type="text" name="device_id" className="form-control" maxLength="5" value={this.state.device_id} onChange={this.handleInputChange} autoComplete="off" required />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-xs-12 col-md-12">
                          <label htmlFor="serial_number">Serial Number</label>
                          <input type="number" name="serial_number" className="form-control" value={this.state.serial_number} onChange={this.handleInputChange} required />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-xs-12 col-md-12">
                          <label htmlFor="strings">Strings</label>
                          <input type="number" name="strings" className="form-control" value={this.state.strings} onChange={this.handleInputChange} required />
                        </div>
                      </div>                      
                      <div className="row mb-3">
                        <div className="col-xs-12 col-md-12">
                          <label htmlFor="is_active">Status</label>
                          <select name="is_active" className="form-select" value={this.state.is_active} onChange={this.handleInputChange} required>
                            <option value="0"> Inactive </option>
                            <option value="1"> Active </option>
                          </select>
                        </div>
                      </div>
                      <div>
                        <input type="submit" className="btn btn-primary fw-bold" value="Submit" />
                        <button type="button" className="btn btn-outline-secondary fw-bold ms-3" onClick={()=> this.setState({show: false})}>Cancel</button>
                      </div>
                    </fieldset>
                  </form>
                </div>
              }
            </div>
          </div>
        </>
      );
    }
    else {
      return(
        <>Loading...</>
      );
    }
  }
}

const SmbList = (props)=> {
  const smb_list = props.data.map((item)=>
    <tr key={item.id} id={item.id}>
        <td className="text-nowrap">{item.device_id}</td>
        <td className="text-nowrap">{item.serial_number}</td>
        <td className="text-nowrap">{item.strings}</td>
        <td className={item.is_active?"text-success":"text-danger"}>{item.is_active?"Active":"Inactive"}</td>
        <td><i id={item.id} className="fa-solid fa-pencil text-warning action-icons" onClick={props.edit}></i></td>
        <td><i id={item.id} className="fa-regular fa-trash-alt text-danger action-icons" onClick={props.delete}></i></td>
    </tr>
  );

  return(
    <div className="table-responsive bg-white p-2 shadow">
      <h5 className="mx-2 fw-bold">String Monitoring Box.</h5>
      <table className="table table-light table-striped table-hover">
        <thead>
            <tr>
                <th>Device ID</th>
                <th>Serial Number</th>
                <th>Strings</th>
                <th>Status</th>
                <th></th>
                <th></th>
            </tr>
        </thead>
        <tbody>
          {smb_list}
        </tbody>
      </table>
    </div>
  );
}

const ParkList = (props)=> {
  const park_list = props.data.map((item)=>
    <option key={item.id} value={item.id}>{item.name}</option>
  );

  return(
    <select name="park" className="form-select" onChange={props.change} required>
      <option key="0" value="">Select Park</option>
      {park_list}
    </select>
  );
}

const PlantList = (props)=> {
  const plant_list = props.data.map((item)=>
    <option key={item.id} value={item.id}>{item.name}</option>
  );

  return(
    <select name="plant" className="form-select" onChange={props.change} required>
      <option key="0" value="">Select Plant</option>
      {plant_list}
    </select>
  );
}

const InverterList = (props)=> {
  const inverter_list = props.data.map((item)=>
    <option key={item.id} value={item.id}>{item.device_id}&nbsp;{item.ip_address}</option>
  );

  return(
    <select name="inverter_id" className="form-select" onChange={props.change} required>
      <option key="0" value="">Select Inverter</option>
      {inverter_list}
    </select>
  );
}

export { Smbs };
