import { Component, useContext, useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { PlantContext, updateData, AuthContext } from '../utils/constants.js';

class PlantList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading     :false,
      currentPlant  :{
        id  : "",
        name: "",
        inverters: 0,
        smbs: 0,
        mfms: 0
      }
    };
    this.handlePlantChange = this.handlePlantChange.bind(this);
  }

  async handlePlantChange(e) {
    var element = e.target;
    const plant_id = parseInt(element.attributes.plant_id.value);
    const plant_name = element.attributes.plant_name.value;
    const inverters = element.attributes.inverters.value;
    const smbs = element.attributes.smbs.value;
    const mfms = element.attributes.mfms.value;    
    if(plant_id && plant_name){
      var plants = this.props.plantData;
      await sessionStorage.setItem("plantState", JSON.stringify({ id:plant_id, name:plant_name, inverters: inverters, smbs: smbs, mfms: mfms }));
      this.props.setCurrentPlant({ id:plant_id, name:plant_name, inverters: inverters, smbs: smbs, mfms: mfms });      
    }
  }

  render() {
    if(!this.state.isLoading){
      var plants = this.props.plantData;
      console.log(plants);
      const plants_list = plants.map((plant)=>
        <div key={plant.id} className="col-md-3">
          <div className="card shadow-sm plant-link mb-3 mx-auto">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12 mb-2">
                  <h5 className="text-uppercase text-center">{plant.name}</h5>
                </div>
              </div>
              <table className="table table-bordered table-striped">
                {/* <tbody>
                  <tr>
                    <td>Daily Power Supplied</td>
                    <td className="text-end">0</td>
                  </tr>
                  <tr>
                    <td>Total Power Supplied</td>
                    <td className="text-end">0</td>
                  </tr>
                  <tr>
                    <td>DC Input</td>
                    <td className="text-end">0</td>
                  </tr>
                  <tr>
                    <td>Active Power</td>
                    <td className="text-end">0</td>
                  </tr>
                </tbody> */}
              </table>
              <div className="d-grid gap-2">
                <Link to="/inverter" plant_id={plant.id} className="btn btn-sm btn-outline-primary fw-bold" plant_name={plant.name} inverters={plant.inverters} smbs={plant.smbs} mfms={plant.mfms}  onClick={this.handlePlantChange}>
                  Go
                </Link>
              </div>
            </div>
        </div>
        </div>
      );
      return(
        <>
          <div className="container dashboard-plant-container">
            <div className="row justify-content-center align-items-center full-height">
              {plants_list}
            </div>
          </div>
        </>
      );
    }
  }
}

function Dashboard(){
  const [plantData, setPlantData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const { signOut } = useContext(AuthContext);
  const isLoggedIn = sessionStorage.getItem("userToken");

  const getPlants = async()=> {
    try {
      const response = await fetch('/api/my-plants');
      const json = await response.json();
      setPlantData(json);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(()=>{
    getPlants();    
  },[]);

  if(isLoggedIn){
    return (
      <>
        {!isLoading && plantData &&
          <PlantContext.Consumer>
            {({id, name, setCurrentPlant}) => (
              <div className="row g-0" id="length">
                <div className="col-12 bg-light" id="background_logo">
                  <div className="text-end">
                    <button className="btn mt-2 me-2" onClick={signOut}>
                      <i className="fa-solid fa-arrow-right-from-bracket"></i> Logout
                    </button>
                  </div>
                  <PlantList plantData={plantData} setCurrentPlant={setCurrentPlant} />
                </div>
              </div>
            )}
          </PlantContext.Consumer>
        }
      </>
    )
  }else{
    return (
      <Navigate to="/login" />
    );
  }
}

class PlantDetails extends Component {
  constructor(props){
    super(props);
    this.state = {
      data : {},
      isLoading : true,
      show : false
    };
    this.edit = this.edit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async getPlantDetails(){
    try {
      const response = await fetch('/api/plants');
      const json = await response.json();
      this.setState({ data:json.length?json[0]:{} });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  edit(e) {
    const plant = this.state.data;
    this.setState(plant);
    this.setState({ show: true });
  }

  componentDidMount() {
    this.getPlantDetails();
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = this.state;
    this.setState({ show: false, isLoading: true });
    delete data.data;
    delete data.isLoading;
    if(data.id){
      updateData('/api/plant/'+data.id, data)
        .then((response) => {
          console.log(response);
          this.getPlantDetails();
      });
    }
  }

  render() {
    if(!this.state.isLoading) {
      const data = this.state.data;
      return(
        <>
          {!this.state.show?(
            <div className="p-3 bg-light shadow-sm rounded">
              <h4 className="mb-3 fw-bold">My Plant details </h4>
              <div className="row">
                <div className="col-md-12 mb-3">
                  <p className="mb-1">Plant Name</p>
                  <h6>{data.name}</h6>
                </div>
                <div className="col-md-12 mb-3">
                  <p className="mb-1">Address</p>
                  <h6>{data.address}</h6>
                </div>
                <div className="col-md-6 mb-3">
                  <p className="mb-1">District</p>
                  <h6>{data.district}</h6>
                </div>
                <div className="col-md-6 mb-3">
                  <p className="mb-1">State Code</p>
                  <h6>{data.state_code}</h6>
                </div>
                <div className="col-md-6 mb-3">
                  <p className="mb-1">Country Code</p>
                  <h6>{data.country_code}</h6>
                </div>
                <div className="col-md-6 mb-3">
                  <p className="mb-1">Park ID</p>
                  <h6>{data.park_id}</h6>
                </div>
                <div className="col-md-6 mb-3">
                  <p className="mb-1">Plant ID</p>
                  <h6>{data.id}</h6>
                </div>
                <div className="col-md-12 mb-3">
                  <button className="mb-1 btn btn-sm btn-warning" onClick={this.edit}><i className="fa-solid fa-pencil"></i> Edit</button>
                </div>
              </div>
            </div>
          ):(
            <div className="user-form p-3 bg-light shadow-sm rounded">
              <h4 className="mb-3 fw-bold"> Edit Plant details </h4>
              <form onSubmit={this.handleSubmit}>
                <fieldset id="user-from" className="my-0 mx-auto">
                  <div className="row mb-3">
                    <div className="col-xs-12 col-md-6">
                      <label htmlFor="name">Plant Name</label>
                      <input type="text" name="name" className="form-control" maxLength="100" value={this.state.name} onChange={this.handleInputChange} required />
                    </div>
                    <div className="col-xs-12 col-md-6">
                      <label htmlFor="park_id">Park ID</label>
                      <input type="number" name="park_id" className="form-control" value={this.state.park_id} onChange={this.handleInputChange} required />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-xs-12 col-md-6">
                      <label htmlFor="address">Address</label>
                      <textarea name="address" className="form-control" rows="2" maxLength="100" value={this.state.address} onChange={this.handleInputChange} required />
                    </div>
                    <div className="col-xs-12 col-md-6">
                      <label htmlFor="district">District</label>
                      <input type="text" name="district" maxLength="50" className="form-control" value={this.state.district} onChange={this.handleInputChange} required />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-xs-12 col-md-6">
                      <label htmlFor="state_code">State Code</label>
                      <select name="state_code" className="form-select" value={this.state.state_code} onChange={this.handleInputChange} required>
                        <option value=""> -- Select State -- </option>
                        <option value="32">Kerala</option>
                        <option value="33">Tamilnadu</option>
                      </select>
                    </div>
                    <div className="col-xs-12 col-md-6">
                      <label htmlFor="country_code">Country Code</label>
                      <input type="text" name="country_code" className="form-control" maxLength="3" value={this.state.country_code} onChange={this.handleInputChange} required />
                    </div>
                  </div>
                  <div>
                    <input type="submit" className="btn btn-primary fw-bold" value="Submit" />
                    <button type="button" className="btn btn-outline-secondary fw-bold ms-3" onClick={()=> this.setState({show: false})}>Cancel</button>
                  </div>
                </fieldset>
              </form>
            </div>
          )}
        </>
      );
    }
    else {
      return(
        <>Loading...</>
      );
    }
  }
}

class AdminDashboard extends Component {
  render() {
    const isLoggedIn = sessionStorage.getItem("userToken");
    if(isLoggedIn){
      return (
        <>
          <h5>Hi.. Welcome to Macsoft DVN Plant Configuration Dashboard. </h5>
          <PlantDetails />
        </>
      );
    }else{
      return (
        <Navigate to="login" />
      );
    }
  }
}

export { Dashboard, AdminDashboard };
