import { createContext } from 'react';
const AuthContext = createContext();

const PlantContext = createContext({
  id: "",
  name: "",
  setCurrentPlant: () => {}
});

async function postData (url = '', data = {}) {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
  return response.json();
}

async function updateData (url = '', data = {}) {
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
  return response.json();
}

async function deleteItem (url = '') {
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({data:null})
  });
  return response.json();
}

export { AuthContext, PlantContext, postData, updateData, deleteItem };
