import { Component } from 'react';
import { postData, updateData, deleteItem } from '../utils/constants.js';

class Inverters extends Component {
  constructor(props){
    super(props);
    this.state = {
      parks: [],
      plants: [],
      data : [],
      isLoading : true,
      show : false
    };
    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
    this.delete = this.delete.bind(this);
    this.handleParkChange = this.handleParkChange.bind(this);
    this.handlePlantChange = this.handlePlantChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async getParks(){
    try {
      const response = await fetch('/api/parks');
      const json = await response.json();
      this.setState({ parks:json, isLoading: false });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async getPlants(id){
    try {
      const response = await fetch('/api/plants/'+id);
      const json = await response.json();
      this.setState({ plants:json, data:[], isLoading: false});
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }
  
  async getInverters(id){
    try {
      const response = await fetch('/api/inverters/'+id);
      const json = await response.json();
      this.setState({ data:json, show: false });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  add() {
    this.setState({
      id: "",
      device_id: "",
      ip_address: "",
      capacity: "",
      is_active: 1,
      show: true
    });
  }

  edit(e) {
    const id = parseInt(e.target.id);
    const inverters = this.state.data;
    const selectedInverter = inverters.filter(e=> e.id === id)[0];
    this.setState(selectedInverter);
    this.setState({ show: true});
  }

  delete(e) {
    const id = parseInt(e.target.id);
    deleteItem("/api/inverter/"+id)
    .then((response) => {
      console.log(response);
      this.getInverters(this.state.plant_id);
    });
  }

  componentDidMount() {
    this.getParks();
  }

  handleParkChange(event) {
    const target = event.target;
    if(event.target.value){
      this.getPlants(event.target.value);
    }else{
      this.setState({ data:[] });
    }
  }

  handlePlantChange(event) {
    const target = event.target;
    if(target.value){
      this.setState({plant_id: target.value});
      this.getInverters(target.value);
    }else{
      this.setState({ data:[] });
    }
  }    

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = this.state;
    if(data.id){
      updateData('/api/inverter/'+data.id, data)
        .then((response) => {
          this.getInverters(this.state.plant_id);
      });
    }else{
      postData('/api/inverter', data)
        .then((response) => {
          this.getInverters(this.state.plant_id);
      });
    }
  }

  render() {
    if(!this.state.isLoading) {
      return(
        <>
            <div className="row g-0">
              <div className="col-sm-6 col-md-3">
                <ParkList data={this.state.parks} change={this.handleParkChange} />
              </div>
              <div className="col-sm-6 col-md-4">
                <PlantList data={this.state.plants} change={this.handlePlantChange} />
              </div>              
              <div className="col-sm-6 col-md-5">
                <button className="btn btn-sm btn-primary float-end fw-bold mb-1" onClick={this.add}>
                  <i className="fa-solid fa-plus"></i> Add Inverter
                </button>
              </div>
            </div>            
            <div className="row">
              <div className="col-sm-12 col-md-8">
                <InverterList data={this.state.data} edit={this.edit} delete={this.delete} />
              </div>
              <div className="col-sm-12 col-md-4">
                {this.state.show &&
                  <>
                    <div className="inverter-form p-3 bg-light shadow-sm rounded">
                      <h4 className="mb-3 fw-bold"> {this.state.id?"Edit Inverter":"New Inverter"} </h4>
                      <form onSubmit={this.handleSubmit}>
                        <fieldset id="inverter-from" className="my-0 mx-auto">
                          <div className="row mb-3">
                            <div className="col-xs-12 col-md-12">
                              <label htmlFor="device_id">Name</label>
                              <input type="text" name="device_id" className="form-control" maxLength="50" value={this.state.device_id} onChange={this.handleInputChange} autoComplete="off" required />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-xs-12 col-md-12">
                              <label htmlFor="ip_address">IP</label>
                              <input type="text" name="ip_address" className="form-control" maxLength="100" value={this.state.ip_address} onChange={this.handleInputChange} autoComplete="off" required />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-xs-12 col-md-12">
                              <label htmlFor="capacity">Capacity</label>
                              <select name="capacity" className="form-control" value={this.state.capacity} onChange={this.handleInputChange} autoComplete="off" required>
                                <option value=""></option>
                                <option value="1 MW">1 MW</option>
                                <option value="1.5 MW">1.5 MW</option>
                                <option value="2 MW">2 MW</option>
                                <option value="3 MW">3 MW</option>
                              </select>
                            </div>
                          </div>                          
                          <div className="row mb-3">
                            <div className="col-xs-12 col-md-12">
                              <label htmlFor="title">Status</label>
                              <select name="is_active" className="form-select" value={this.state.is_active} onChange={this.handleInputChange} required>
                                <option value="0"> Inactive </option>
                                <option value="1"> Active </option>
                              </select>
                            </div>
                          </div>
                          <div>
                            <input type="submit" className="btn btn-primary fw-bold" value="Submit" />
                            <button type="button" className="btn btn-outline-secondary fw-bold ms-3" onClick={()=> this.setState({show: false})}>Cancel</button>
                          </div>
                        </fieldset>
                      </form>
                    </div>
                  </>
                }
              </div>
            </div>
        </>
      );
    }
    else {
      return(
        <>Loading...</>
      );
    }
  }
}

const InverterList = (props)=> {
  const inverter_list = props.data.map((item)=>
    <tr key={item.id} id={item.id}>
        <td className="text-nowrap">{item.device_id}</td>
        <td className="text-nowrap">{item.ip_address}</td>
        <td className="text-nowrap">{item.capacity}</td>
        <td className={item.is_active?"text-success":"text-danger"}>{item.is_active?"Active":"Inactive"}</td>
        <td><i id={item.id} className="fa-solid fa-pencil text-warning action-icons" onClick={props.edit}></i></td>
        <td><i id={item.id} className="fa-regular fa-trash-alt text-danger action-icons" onClick={props.delete}></i></td>
    </tr>
  );

  return(
    <div className="table-responsive bg-white p-2 shadow">
      <h5 className="mx-2 fw-bold">Inverters</h5>
      <table className="table table-light table-striped table-hover">
        <thead>
            <tr>
                <th>Name</th>
                <th>Address</th>
                <th>Capacity</th>
                <th>Status</th>
                <th></th>
                <th></th>
            </tr>
        </thead>
        <tbody>
          {inverter_list}
        </tbody>
      </table>
    </div>
  );
}

const ParkList = (props)=> {
  const park_list = props.data.map((item)=>
    <option key={item.id} value={item.id}>{item.name}</option>
  );

  return(
    <select name="park" className="form-select" onChange={props.change} required>
      <option key="0" value="">Select Park</option>
      {park_list}
    </select>
  );
}

const PlantList = (props)=> {
  const plant_list = props.data.map((item)=>
    <option key={item.id} value={item.id}>{item.name}</option>
  );

  return(
    <select name="plant" className="form-select" onChange={props.change} required>
      <option key="0" value="">Select Plant</option>
      {plant_list}
    </select>
  );
}

export { Inverters };
