import { Link, Navigate } from "react-router-dom";
import { SideNav, Header } from '../partials/layout.js';

function NotFound() {
  const isAdmin = sessionStorage.getItem("isAdminUser");

  return(
    <>
      {isAdmin==="false" &&
        <Header />
      }
      <div className="container-fluid main">
        <div className="row">
          <SideNav/>
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              {isAdmin==="true" &&
                <div className="mt-5"></div>
              }
              <div className="container-fluid">
                 <div className="text-center">
                  <div className="error mx-auto" data-text="404">404</div>
                  <p className="lead text-gray-800 mb-5">Page Not Found</p>
                  <p className="text-gray-500 mb-0">It looks like you found a glitch in the matrix...</p>
                  <Link to="/">&larr; Back to Dashboard</Link>
                 </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { NotFound };
